import axios from "@/utils/axios";
import { upimg } from "@/api";
import { audioPaly } from "@/utils";
import { tonguefail , yemian , tongueover , facetotongue } from "@/utils/audio";

let step = {
    namespaced: true,
    state: {
      loading:false,
      show:false,
      no_show:false,
      explain:'',
      photoNun:1
    },
    mutations: {
      //重新检测
      againFun(state){
        state.no_show = false;
        state.loading = false;
        state.photoNun = 1;
      },
      //发送请求
      faceAxios (state, params) {
        let _this = this;
        state.loading = true;
        let flow = sessionStorage.getItem('flow');
        let flow_step = "";
        if(flow){
          flow_step = JSON.parse(flow).flow_info.flow_step;
        }

        if(flow_step==13){
          params.data.type = 3;
        }

        axios (`/api${upimg}`, params.data,'POST').then ((data) => {
          if(data.code==1){
            state.loading = false;
            if(params.data.type==1){
                sessionStorage.setItem("faceID", data.data.id);
                if(flow_step==1||flow_step==6){ //面
                  state.explain = '面诊成功，即将进入问诊';
                  //语音
                  audioPaly.play(facetotongue);
                  setTimeout(function(){
                    state.explain = '';
                    let device = sessionStorage.getItem('device');
                    if(device && !(device=='android' || device=='windows') && (flow_step==5||flow_step==6)){
                      params._this.$router.push({
                        name:'prescribeQuestion'
                      });
                    }else{
                      params._this.$router.push({
                        name:'question'
                      });
                    }
                  },2000)
                }else{    //其他跳舌诊
                  state.explain = '面诊成功，即将进入舌诊';
                  setTimeout(function(){
                    state.explain = '';
                    params._this.$router.push({
                      name:'confirmTongue'
                    });
                  },2000)
                }
            }else{
                sessionStorage.setItem("tongueID", data.data.id);
                if(flow_step==13){
                  _this.dispatch('question/submitData',{_this:params._this});
                }else{
                  state.explain = '舌诊成功，即将进入问诊';
                  setTimeout(function(){
                    state.explain = '';
                    //语音
                    audioPaly.play(tongueover);
                    params._this.$router.push({
                      name:'confirmAsk'
                    });
                  },2000)
                }
            }
          }else{
            state.loading = false;
            state.no_show = data.msg;
            state.photoNun = 0;
            if(params.data.type==1){
              //语音
              audioPaly.play(yemian);
            }else{
              //语音
              audioPaly.play(tonguefail);
            }
          }
        }).catch((error)=>{
          state.loading = false;
          state.no_show = error.msg;
          state.photoNun = 0;
        });
      }
    },
    actions: {
      //异步方法
      axiosData ({ commit }, params) {
        commit ("faceAxios", params);
      }
    },
    getters: {},
  };
  
  export default step;